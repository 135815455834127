

















































import Vue from "vue";
import {
  get_color_for_percentage,
  get_pass_or_fail_class
} from "@/utils/global";

export default Vue.extend({
  name: "GoBackHeaderPercentage",
  data() {
    return {
      reason: "",
      item: 2.4
    };
  },
  props: {
    title: {
      type: String
    },
    showBackButton: {
      type: Boolean,
      default: true
    },
    score: {
      type: Number
    },
    showPassAndFail: {
      type: Boolean,
      default: true
    },
    name: {
      type: String
    }
  },
  computed: {
    is_pass() {
      return this.score >= 50;
    }
  },
  methods: {
    get_color_for_percentage,
    get_pass_or_fail_class
  }
});
